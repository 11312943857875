import React from 'react'

const IconMobileMockup = () => (
   <svg viewBox="0 0 291 579" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         d="M12.6982 0.5H50.4855C55.0544 0.5 58.7582 4.20383 58.7582 8.77273C58.7582 13.8939 62.9097 18.0455 68.0309 18.0455H222.969C228.09 18.0455 232.242 13.8939 232.242 8.77273C232.242 4.20383 235.946 0.5 240.515 0.5H278.302C285.051 0.5 290.5 5.8328 290.5 12.385V566.615C290.5 573.167 285.051 578.5 278.302 578.5H12.6982C5.94947 578.5 0.5 573.167 0.5 566.615V12.385C0.5 5.83281 5.94947 0.5 12.6982 0.5Z"
         stroke="#FFE4DE"
      />
      <rect x="123.5" y="1.5" width="32" height="7" rx="3.5" stroke="#FFE4DE" />
      <rect x="160.5" y="1.5" width="7" height="7" rx="3.5" stroke="#FFE4DE" />
   </svg>
)

export default IconMobileMockup
