import React from 'react'
import { useTheme } from 'styled-components'
import PropTypes from 'prop-types'

const IconFolder = ({ color }) => {
   const theme = useTheme()
   return (
      <svg
         width="18"
         height="18"
         viewBox="0 0 14 14"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.0313 1.87842C7.0313 1.23876 6.51275 0.720215 5.87309 0.720215H2.39648C1.11717 0.720215 0.0800781 1.75731 0.0800781 3.03662V8.82997V11.1464C0.0800781 12.4261 1.11747 13.4635 2.39715 13.4635H11.6654C12.9451 13.4635 13.9825 12.4261 13.9825 11.1464V5.35369C13.9825 4.07401 12.9451 3.03662 11.6654 3.03662H8.1895C7.54984 3.03662 7.0313 2.51808 7.0313 1.87842Z"
            fill={theme.color[color]}
         />
      </svg>
   )
}

export default IconFolder

IconFolder.propTypes = {
   color: PropTypes.string,
}

IconFolder.defaultProps = {
   color: 'primaryLight',
}
