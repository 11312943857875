import React from 'react'
import { useTheme } from 'styled-components'
import PropTypes from 'prop-types'

const IconStructure = ({ color }) => {
   const theme = useTheme()
   return (
      <svg
         width="21"
         height="18"
         viewBox="0 0 21 18"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <rect x="4.81641" width="10" height="2" fill={theme.color[color]} />
         <rect x="0.816406" width="2" height="2" fill={theme.color[color]} />
         <rect
            x="0.816406"
            y="5"
            width="2"
            height="2"
            fill={theme.color[color]}
         />
         <rect
            x="4.81641"
            y="5"
            width="10"
            height="2"
            fill={theme.color[color]}
         />
         <rect
            x="12.8164"
            y="11"
            width="8"
            height="2"
            fill={theme.color[color]}
         />
         <rect
            x="12.8164"
            y="16"
            width="8"
            height="2"
            fill={theme.color[color]}
         />
      </svg>
   )
}

export default IconStructure

IconStructure.propTypes = {
   color: PropTypes.string,
}

IconStructure.defaultProps = {
   color: 'black',
}
