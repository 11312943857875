import React from 'react'
import styled, { css } from 'styled-components'
import Portal from '../../../../portal'
import Button from '../../atoms/Button/Button'
import IconDropdownClose from '../../atoms/Icons/IconDropdownClose'

const Wrapper = styled.div`
   height: 100%;
   width: 320px;
   background-color: ${({ theme }) => theme.color.white};
   padding: 2rem 1rem 2rem 3rem;

   position: fixed;
   top: 0;
   left: -320px;
   z-index: 99;
   transition: ${({ theme }) => theme.transition};
   ${({ active }) =>
      active &&
      css`
         left: 0;
         box-shadow: ${({ theme }) => theme.shadow1.hover};
      `}
`
const Header = styled.div`
   width: 100%;
   display: flex;
   justify-content: flex-end;
   margin-bottom: 2rem;
`
const Inner = styled.div`
   width: 100%;
`

const AsideModal = ({ children, active, clickFn }) => {
   return (
      <Portal>
         <Wrapper className="scroll" active={active}>
            <Header>
               <Button small onClick={clickFn} secondary square black>
                  <IconDropdownClose
                     small
                     color="black"
                     width="16px"
                     height="16px"
                  />
               </Button>
            </Header>
            <Inner>{children}</Inner>
         </Wrapper>
      </Portal>
   )
}

export default AsideModal
