import React from 'react'
import styled from 'styled-components'
import SecondDropdown from '../SecondDropdown/SecondDropdown'
import arrow from '../../../assets/images/dropdown-arrow.svg'

const Inner = styled.ul`
   display: flex;
   flex-direction: column;
   width: 20%;
   height: 100%;
   border-right: 2px solid #f5f5f5;
`

const Item = styled.li`
   cursor: pointer;
   padding: 0.75rem 0;
   transition: ${({ theme }) => theme.transition};
   padding-right: 1rem;
   display: flex;
   align-items: center;
   justify-content: space-between;
   gap: 0.5rem;
   img {
      width: 6px;
   }
   &:first-child {
      padding-top: 0;
   }
   &:last-child {
      padding-bottom: 0;
   }
   &:hover {
      color: ${({ theme }) => theme.color.primary};
      .dropdown__second {
         opacity: 1;
         visibility: visible;
      }
   }
   & > .dropdown__second {
      opacity: 0;
      visibility: hidden;
   }
`

const DesktopDropdown = ({ items }) => {
   return (
      <Inner className="scroll">
         {items.map((item) => (
            <Item key={item['ID']}>
               {item['Anchor']}
               {item.children.length > 0 && <img src={arrow} alt="arrow" />}
               {item.children?.length ? (
                  <SecondDropdown items={item.children} />
               ) : null}
            </Item>
         ))}
      </Inner>
   )
}

export default DesktopDropdown
