import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
   width: 80%;
   height: 100%;
   padding: 0 2rem;
   position: absolute;
   left: 20%;
   top: 0;
   background-color: ${({ theme }) => theme.color.white};
   transition: ${({ theme }) => theme.transition};
`
const Inner = styled.ul`
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
   align-content: flex-start;
   gap: 1rem;
   height: ${({ height }) => `calc(100% + ${height}px)`};
   width: calc(100% - 16px);
`
const Item = styled.li`
   cursor: pointer;
   transition: ${({ theme }) => theme.transition};
   width: 25%;
   color: ${({ theme }) => `${theme.color.black}70`};
   &:first-child {
      padding-top: 0;
   }
   &:last-child {
      padding-bottom: 0;
   }
   &:hover {
      color: ${({ theme }) => theme.color.primary};
   }
`

const SecondDropdown = ({ items }) => {
   const refWrapper = useRef()
   const [height, setHeight] = useState(0)
   useEffect(() => {
      const countHeight = () => {
         const wrapperHeight = refWrapper.current.clientHeight
         const childrenHeight = [...refWrapper.current.children]
            .map((item) => item.clientHeight + 16)
            .reduce((accumulator, curr) => accumulator + curr)
         const heightToAdd = wrapperHeight - (childrenHeight / 4 + 64)
         heightToAdd < 0 && setHeight(heightToAdd * -1)
      }
      countHeight()
   }, [])
   return (
      <Wrapper className="dropdown__second scroll">
         <Inner ref={refWrapper} height={height}>
            {items.map((item) => (
               <Item key={item['ID']}>{item['Anchor']}</Item>
            ))}
         </Inner>
      </Wrapper>
   )
}

export default SecondDropdown
