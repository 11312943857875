import React from 'react'

const IconCascadeMenu = () => (
   <svg
      width="212"
      height="109"
      viewBox="0 0 212 109"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <rect
         x="144"
         y="44.8457"
         width="68"
         height="18"
         rx="7.76629"
         fill="#FFE4DE"
      />
      <rect
         y="0.845703"
         width="60"
         height="10.3551"
         rx="5.17753"
         fill="#F58677"
      />
      <rect
         y="25.2012"
         width="60"
         height="10.3551"
         rx="5.17753"
         fill="#F58677"
      />
      <rect
         x="74"
         y="24.8457"
         width="60"
         height="10.3551"
         rx="5.17753"
         fill="#F5F4F3"
      />
      <rect
         x="74"
         y="0.845703"
         width="60"
         height="10.3551"
         rx="5.17753"
         fill="#F5F4F3"
      />
      <rect
         x="148"
         y="24.8457"
         width="60"
         height="10.3551"
         rx="5.17753"
         fill="#F5F4F3"
      />
      <rect
         x="148"
         y="0.845703"
         width="60"
         height="10.3551"
         rx="5.17753"
         fill="#F5F4F3"
      />
      <rect
         y="49.5566"
         width="60"
         height="10.3551"
         rx="5.17753"
         fill="#F58677"
      />
      <rect
         x="74"
         y="49.2012"
         width="60"
         height="10.3551"
         rx="5.17753"
         fill="#F5F4F3"
      />
      <rect
         x="148"
         y="48.8457"
         width="60"
         height="10.3551"
         rx="5.17753"
         fill="#F5F4F3"
      />
      <rect
         y="73.9121"
         width="60"
         height="10.3551"
         rx="5.17753"
         fill="#F58677"
      />
      <rect
         x="74"
         y="73.5566"
         width="60"
         height="10.3551"
         rx="5.17753"
         fill="#F5F4F3"
      />
      <rect
         x="148"
         y="73.8457"
         width="60"
         height="10.3551"
         rx="5.17753"
         fill="#F5F4F3"
      />
      <rect
         x="74"
         y="97.9121"
         width="60"
         height="10.3551"
         rx="5.17753"
         fill="#F5F4F3"
      />
      <rect
         x="148"
         y="97.8457"
         width="60"
         height="10.3551"
         rx="5.17753"
         fill="#F5F4F3"
      />
   </svg>
)

export default IconCascadeMenu
