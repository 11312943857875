import React from 'react'
import styled from 'styled-components'
import Container from '../../atoms/Container/Container'
import ErrorBox from '../../atoms/ErrorBox/ErrorBox'

const Wrapper = styled.div`
   padding: 4rem 0;
   max-width: 600px;
   margin: 0 auto;
   display: flex;
   align-items: center;
   height: 100%;
`

const TreePageError = () => (
   <Container>
      <Wrapper>
         <ErrorBox>
            Coś poszło nie tak. Upewnij się, że ID arkusza jest poprawne oraz,
            że jest on udostępniony jako publiczny.
         </ErrorBox>
      </Wrapper>
   </Container>
)

export default TreePageError
