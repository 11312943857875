import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import IconDropdownClose from '../../atoms/Icons/IconDropdownClose'
import IconFolder from '../../atoms/Icons/IconFolder'
import Structure from '../../molecules/Structure/Structure'
import IconDropdownMinus from '../Icons/IconDropdownMinus'

const Item = styled.li`
   width: 100%;
   position: relative;
   padding-left: 20px;
   overflow: hidden;
   svg {
      flex-shrink: 0;
   }
   ${({ dropdown }) =>
      dropdown &&
      css`
         cursor: pointer;
      `}
   ${({ third }) =>
      third &&
      css`
         &:before {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 8px;
            background-color: ${({ theme }) => theme.color.gray};
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
         }
      `}
`
const Inner = styled.span`
   width: 100%;
   display: flex;
   gap: 0.5rem;
   align-items: center;
`
const Plus = styled.div`
   position: absolute;
   left: 0;
   width: 12px;
   height: 12px;
   border-radius: 2px;
   background-color: ${({ theme }) => theme.color.gray};
   display: flex;
   align-items: center;
   justify-content: center;
   svg {
      transform: rotate(45deg);
   }
`
const StructureItem = ({ items, children, second, third }) => {
   const [active, setActive] = useState(false)
   const clickHandler = (e) => {
      e.stopPropagation()
      setActive(!active)
   }
   return (
      <Item
         active={active ? true : false}
         onClick={clickHandler}
         dropdown={items.length > 0 ? true : false}
         third={third}
      >
         <Inner>
            {items.length > 0 && (
               <Plus>
                  {!active ? (
                     <IconDropdownClose
                        color="white"
                        width="6px"
                        height="6px"
                     />
                  ) : (
                     <IconDropdownMinus
                        color="white"
                        width="6px"
                        height="6px"
                     />
                  )}
               </Plus>
            )}

            {!third && <IconFolder />}
            {children}
         </Inner>

         {items.length > 0 && (
            <Structure
               active={active ? true : false}
               second
               third={second ? true : false}
               items={items}
            />
         )}
      </Item>
   )
}

export default StructureItem
