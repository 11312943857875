import React, { useContext, useState } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import Button from '../../atoms/Button/Button'
import Container from '../../atoms/Container/Container'
import logo from '../../../assets/images/logo.svg'
import IconDesktop from '../../atoms/Icons/IconDesktop'
import IconMobile from '../../atoms/Icons/IconMobile'
import IconStructure from '../../atoms/Icons/IconStructure'
import IconRefresh from '../../atoms/Icons/IconRefresh'
import IconCopy from '../../atoms/Icons/IconCopy'
import { myContext } from '../../../../provider'
import AsideModal from '../AsideModal/AsideModal'
import Structure from '../Structure/Structure'
import Info from '../Info/Info'

const Wrapper = styled.header`
   padding: 1.5rem 0;
   background-color: ${({ theme }) => theme.color.grayLight};
`
const StyledButton = styled(Button)`
   ${({ active }) =>
      active &&
      css`
         background-color: ${({ theme }) => theme.color.primaryLight};
      `}
`

const Inner = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   position: relative;
   .header__logo {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
         position: relative;
         transform: none;
         left: auto;
         top: auto;
      }
   }
`
const Left = styled.div`
   display: flex;
   flex: 1;
   gap: 1rem;
   @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
      display: none;
   }
`

const Right = styled.div`
   display: flex;
   flex: 1;
   justify-content: flex-end;
   gap: 1rem;
   @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
      a:first-child {
         display: none;
      }
   }
`

const CopyButton = styled(Button)`
   :after {
      content: 'Skopiowano!';
      position: absolute;
      display: block;
      width: 100%;
      left: 0;
      bottom: -50px;
      text-align: center;
      padding: 0.5rem 0;
      transition: ${({ theme }) => theme.transition};
   }
   ${({ copied }) =>
      copied &&
      css`
         :after {
            bottom: 0;
         }
      `}
`
const ButtonInner = styled.div`
   width: 100%;
   display: flex;
   align-items: center;
   gap: 0.5rem;
   transform: translateY(0px);
   transition: ${({ theme }) => theme.transition};

   ${({ copied }) =>
      copied &&
      css`
         transform: translateY(-50px);
      `}
`

const TreePageHeader = ({ refreshData, items }) => {
   const context = useContext(myContext)
   const [structure, setStructure] = useState(false)
   const [copied, setCopied] = useState(false)
   const structureHandler = () => setStructure(!structure)
   const clickHandler = (e) => {
      const type = e.currentTarget.dataset.type
      context.changeMenu({
         ...context.menu,
         type: type,
      })
   }
   const copyHandler = () => {
      const el = document.createElement('input')
      el.value = window.location.href
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      setCopied(true)
      setTimeout(() => {
         setCopied(false)
      }, 2000)
   }
   return (
      <Wrapper>
         <Container>
            <Inner>
               <Left>
                  <StyledButton
                     data-type="desktop"
                     small
                     black
                     secondary
                     blackSecond
                     onClick={clickHandler}
                     active={context.menu.type !== 'mobile' ? true : false}
                  >
                     <IconDesktop />
                     Desktop
                  </StyledButton>
                  <StyledButton
                     data-type="mobile"
                     small
                     black
                     secondary
                     blackSecond
                     onClick={clickHandler}
                     active={context.menu.type === 'mobile' ? true : false}
                  >
                     <IconMobile />
                     Mobile
                  </StyledButton>
                  <StyledButton
                     onClick={structureHandler}
                     small
                     black
                     secondary
                     blackSecond
                     active={structure ? true : false}
                  >
                     <IconStructure />
                     Struktura
                  </StyledButton>
               </Left>

               <Link to="/" className="header__logo">
                  <img
                     src={logo}
                     alt="Drzewko.io - wizualizacja drzewa kategorii"
                  />
               </Link>

               <Right>
                  <Info
                     reverse
                     content="Dane są trzymane w cache przez 30s. Jeżeli widok się nie
                        odświeżył spróbuj ponownie za chwilę."
                  >
                     <Button
                        small
                        black
                        secondary
                        blackSecond
                        onClick={refreshData}
                     >
                        <IconRefresh />
                        odśwież
                     </Button>
                  </Info>

                  <CopyButton copied={copied} small onClick={copyHandler}>
                     <ButtonInner copied={copied}>
                        <IconCopy />
                        Skopiuj link
                     </ButtonInner>
                  </CopyButton>
               </Right>
            </Inner>
         </Container>
         <AsideModal clickFn={structureHandler} active={structure}>
            <Structure items={items} />
         </AsideModal>
      </Wrapper>
   )
}

export default TreePageHeader
