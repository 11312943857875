import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import Container from '../../atoms/Container/Container'
import MobileMockup from '../../molecules/MobileMockup/MobileMockup'
import logo from '../../../assets/images/logo.svg'
import arrow from '../../../assets/images/dropdown-arrow.svg'

const Wrapper = styled.div`
   padding: 4rem 0;
   display: flex;
   justify-content: center;
`
const Header = styled.div`
   padding: 1rem;
   display: flex;
   justify-content: center;
`
const Inner = styled.ul`
   width: 100%;
   height: calc(100% - 3rem);
   position: absolute;
   left: 0;
   top: 50px;
   background-color: ${({ theme }) => theme.color.white};
   z-index: 10;
   ${({ second }) =>
      second &&
      css`
         display: none;
         z-index: 20;
         top: 0;
         height: 100%;
         &.active {
            display: block;
         }
      `}
`
const Item = styled.li`
   padding: 1rem 1rem;
   gap: 0.5rem;
   font-weight: 500;
   border-bottom: 2px solid ${({ theme }) => theme.color.grayLight};
   transition: ${({ theme }) => theme.transition};
   cursor: pointer;
   display: flex;
   align-items: center;
   justify-content: space-between;
   &:hover {
      background-color: ${({ theme }) => theme.color.grayLight};
   }
   ${({ primary }) =>
      primary &&
      css`
         background-color: ${({ theme }) => theme.color.primary};
         color: ${({ theme }) => theme.color.white};
         cursor: auto;
         border: none;
         &:hover {
            background-color: ${({ theme }) => theme.color.primary};
         }
      `}
   ${({ back }) =>
      back &&
      css`
         justify-content: flex-start;
         img {
            transform: rotateY(180deg);
         }
      `}
`
const MobileNavigation = ({ items, loading }) => {
   const [id1, setId1] = useState(false)
   const [id2, setId2] = useState(false)
   const clickHandler = (e, depth) => {
      e.stopPropagation()
      depth === 1 && setId1(e.currentTarget.dataset.id)
      depth === 2 && setId2(e.currentTarget.dataset.id)
   }
   return (
      <Container>
         <Wrapper>
            <MobileMockup>
               <Header>
                  <img
                     src={logo}
                     alt="Drzewko.io - wizualizacja drzewa kategorii"
                  />
               </Header>
               <Inner className={!id1 && !id2 ? 'scroll' : null}>
                  {loading ? (
                     <Item>Ładuję...</Item>
                  ) : (
                     items.map((item) => (
                        <React.Fragment key={item['ID']}>
                           <Item
                              onClick={(e) =>
                                 item.children.length > 0
                                    ? clickHandler(e, 1)
                                    : null
                              }
                              data-id={item['ID']}
                           >
                              {item['Anchor']}{' '}
                              {item.children.length > 0 && (
                                 <>
                                    <img src={arrow} alt="arrow" />
                                    <Inner
                                       second
                                       data-id={item['ID']}
                                       className={`${
                                          id1 === item['ID'] ? 'active' : null
                                       } ${id1 && !id2 ? 'scroll' : null}`}
                                    >
                                       <Item primary>{item['Anchor']}</Item>
                                       <Item
                                          back
                                          onClick={(e) => {
                                             e.stopPropagation()
                                             setId1(false)
                                          }}
                                       >
                                          <img src={arrow} alt="arrow" />
                                          Wróć
                                       </Item>
                                       {item.children.map((item) => (
                                          <React.Fragment key={item['ID']}>
                                             <Item
                                                onClick={(e) =>
                                                   item.children.length > 0
                                                      ? clickHandler(e, 2)
                                                      : null
                                                }
                                                data-id={item['ID']}
                                             >
                                                {item['Anchor']}
                                                {item.children.length > 0 && (
                                                   <>
                                                      <img
                                                         src={arrow}
                                                         alt="arrow"
                                                      />
                                                      <Inner
                                                         second
                                                         data-id={item['ID']}
                                                         className={`${
                                                            id2 === item['ID']
                                                               ? 'active'
                                                               : null
                                                         } scroll`}
                                                      >
                                                         <Item primary>
                                                            {item['Anchor']}
                                                         </Item>
                                                         <Item
                                                            back
                                                            onClick={(e) => {
                                                               e.stopPropagation()
                                                               setId2(false)
                                                            }}
                                                         >
                                                            <img
                                                               src={arrow}
                                                               alt="arrow"
                                                            />
                                                            Wróć
                                                         </Item>
                                                         {item.children.map(
                                                            (item) => (
                                                               <Item
                                                                  key={
                                                                     item['ID']
                                                                  }
                                                                  data-id={
                                                                     item['ID']
                                                                  }
                                                               >
                                                                  {
                                                                     item[
                                                                        'Anchor'
                                                                     ]
                                                                  }
                                                               </Item>
                                                            ),
                                                         )}
                                                      </Inner>
                                                   </>
                                                )}
                                             </Item>
                                          </React.Fragment>
                                       ))}
                                    </Inner>
                                 </>
                              )}
                           </Item>
                        </React.Fragment>
                     ))
                  )}
               </Inner>
            </MobileMockup>
         </Wrapper>
      </Container>
   )
}

export default MobileNavigation
