import React from 'react'
import styled from 'styled-components'
import IconMobileMockup from '../../atoms/Icons/IconMobileMockup'

const Wrapper = styled.div`
   width: 307px;
   height: 594px;
   border-radius: 12px;
   box-shadow: ${({ theme }) => theme.shadow1.normal};
   position: relative;
   border: 2px solid ${({ theme }) => theme.color.primaryLight};
   padding: 28px 0.5rem 3rem 0.5rem;
   svg {
      width: calc(100% - 1rem);
      height: calc(100% - 1rem);
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      @media (max-width: 350px) {
         display: none;
      }
   }
   &:after {
      content: '';
      display: block;
      width: 5.5rem;
      height: 0.5rem;
      position: absolute;
      bottom: 1.5rem;
      left: 50%;
      transform: translateX(-50%);
      background-color: ${({ theme }) => theme.color.grayLight};
      border-radius: 12px;
   }
`

const Inner = styled.div`
   width: calc(100% - 2px);
   margin-left: 1px;
   height: 100%;
   position: relative;
   border-radius: 0 0 12px 12px;
   overflow: hidden;
`

const MobileMockup = ({ children }) => (
   <Wrapper>
      <IconMobileMockup />
      <Inner>{children}</Inner>
   </Wrapper>
)

export default MobileMockup
