import React from 'react'
import styled from 'styled-components'
import Container from '../../atoms/Container/Container'
import IconCascadeMenu from '../../atoms/Icons/IconCascadeMenu'
import IconMegaMenu from '../../atoms/Icons/IconMegaMenu'
import SwitchBox from '../../atoms/SwitchBox/SwitchBox'

const Wrapper = styled.div`
   padding: 10rem 0;
   display: flex;
   gap: 5rem;
   justify-content: center;
`

const TreeSwitcher = () => {
   return (
      <Container>
         <Wrapper>
            <SwitchBox title="Menu kaskadowe" type="desktop">
               <IconCascadeMenu />
            </SwitchBox>
            <SwitchBox title="Mega menu" type="mega">
               <IconMegaMenu />
            </SwitchBox>
         </Wrapper>
      </Container>
   )
}

export default TreeSwitcher
