import React from 'react'
import styled from 'styled-components'
import Container from '../../atoms/Container/Container'
import DesktopNavigation from '../../molecules/DesktopNavigation/DesktopNavigation'

const Wrapper = styled.div`
   border-bottom: 2px solid ${({ theme }) => theme.color.grayLight};
   width: 100%;
   position: relative;
   z-index: 99;
`

const TreeNavigation = ({ items, loading }) => {
   return (
      <Wrapper>
         <Container>
            <DesktopNavigation loading={loading} items={items} />
         </Container>
      </Wrapper>
   )
}

export default TreeNavigation
