import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { myContext } from '../../../../provider'

const Wrapper = styled.div`
   padding: 1rem;
   border: 2px solid ${({ theme }) => theme.color.primaryLight};
   border-radius: 12px;
   cursor: pointer;
   transition: ${({ theme }) => theme.transition};
   &:hover {
      border-color: ${({ theme }) => theme.color.primary};
      box-shadow: ${({ theme }) => theme.shadow1.hover};
   }
   ${({ active }) =>
      active &&
      css`
         border-color: ${({ theme }) => theme.color.primary};
         color: ${({ theme }) => theme.color.primary};
         box-shadow: ${({ theme }) => theme.shadow1.hover};
      `}
`

const Inner = styled.div`
   border-radius: 12px;
   box-shadow: ${({ theme }) => theme.shadow1.normal};
   padding: 2rem 1rem;
   border: 2px solid ${({ theme }) => theme.color.grayLight};
`

const Caption = styled.div`
   text-align: center;
   padding-top: 1rem;
`
const SwitchBox = ({ children, title, type }) => {
   const context = useContext(myContext)
   const clickHandler = (e) => {
      const type = e.currentTarget.dataset.type
      context.changeMenu({
         ...context.menu,
         type: type,
      })
   }
   return (
      <Wrapper
         onClick={clickHandler}
         data-type={type}
         active={context.menu.type === type ? true : false}
      >
         <Inner>{children}</Inner>
         <Caption>{title}</Caption>
      </Wrapper>
   )
}

export default SwitchBox
