import React, { useRef, useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

const Inner = styled.ul`
   display: flex;
   flex-direction: column;
   width: 100%;
   height: 100%;
   flex-wrap: wrap;
   gap: 1rem;
   align-content: flex-start;
   height: ${({ height }) => `${height}px`};
`

const Item = styled.li`
   cursor: pointer;
   width: calc(20% - 1rem);
   color: ${({ theme }) => theme.color.primary};
   padding-right: 1rem;
   transition: ${({ theme }) => theme.transition};
   & > .last {
   }
   ${({ second }) =>
      second &&
      css`
         color: ${({ theme }) => theme.color.black};
         font-weight: 400;
         &:hover {
            color: ${({ theme }) => theme.color.primary};
         }
         /* &.last {
            // border-bottom: 2px solid ${({ theme }) => theme.color.grayLight};
            padding-bottom: 1rem;
            position: relative;
            &:after {
               content: '';
               display: block;
               position: absolute;
               width: calc(100% - 1rem);
               height: 2px;
               background-color: ${({ theme }) => theme.color.grayLight};
               left: 0;
               bottom: -2px;
            }
            &.noborder:after {
               display: none;
            }
         } */
      `}
`

const MegaDropdown = ({ items }) => {
   const refWrapper = useRef()
   const [height, setHeight] = useState(0)
   const [position, setPosition] = useState(0)
   useEffect(() => {
      const countHeight = () => {
         const childrenHeight = [...refWrapper.current.children]
            .map((item) => item.clientHeight + 16)
            .reduce((accumulator, curr) => accumulator + curr)
         const heightToAdd = childrenHeight / 5 + 16
         const children = [...refWrapper.current.children]
         children.length <= 5 ? setHeight(60) : setHeight(heightToAdd)
      }
      const lowestPosition = () => {
         const items = [...refWrapper.current.children]
         const positions = items.map((item) => item.offsetTop)
         const lower = Math.max(...positions)
         setPosition(lower)
         items.forEach((el) => {
            const elPosition = el.offsetTop
            if (
               lower > 0 &&
               elPosition === lower &&
               !el.className.includes('noborder')
            ) {
               el.className += ' noborder'
            }
         })
      }
      countHeight()
      lowestPosition()
   })
   return (
      <Inner ref={refWrapper} height={height}>
         {items.map((item, index1) => (
            <React.Fragment key={item['ID']}>
               <Item>{item['Anchor']}</Item>
               {item.children?.length
                  ? item.children.map((item2, index) => (
                       <Item
                          position={position}
                          second
                          key={item2['ID']}
                          className={
                             index === item.children.length - 1 &&
                             index1 !== items.length - 1
                                ? 'last'
                                : ''
                          }
                       >
                          {item2['Anchor']}
                       </Item>
                    ))
                  : null}
            </React.Fragment>
         ))}
      </Inner>
   )
}

export default MegaDropdown
