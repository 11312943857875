import * as React from 'react'
import { useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import { myContext } from '../../../provider'
import Layout from '../../components/Layout/Layout'
import { fetchData, createMenu } from '../../assets/functions'
import TreePageHeader from '../../components/molecules/TreePageHeader/TreePageHeader'
import TreeNavigation from '../../components/organisms/TreeNavigation/TreeNavigation'
import MobileNavigation from '../../components/organisms/MobileNavigation/MobileNavigation'
import TreeSwitcher from '../../components/organisms/TreeShwitcher/TreeSwitcher'
import TreePageError from '../../components/organisms/TreePageError/TreePageError'
import Seo from '../../components/Seo/Seo'
import { Helmet } from 'react-helmet'

const HiddenBig = styled.div`
   @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
      display: none;
   }
`

const HiddenSmall = styled.div`
   @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
      display: none;
   }
`

// markup
const TreePage = ({ params, location }) => {
   const testContext = useContext(myContext)
   const [data, setData] = useState([])
   const [loading, setLoading] = useState(true)
   const { id } = params

   const refreshData = async () => {
      setLoading(true)
      const fetched = await fetchData(id)
      testContext.changeMenu({
         ...testContext.menu,
         data: fetched,
         source: 'url',
      })
      const menu = fetched && createMenu(fetched)
      setData(menu)
      setLoading(false)
   }

   useEffect(() => {
      const getData = async () => {
         let menu = null
         setLoading(true)
         if (testContext.menu.source === 'form' && testContext.menu.data) {
            menu = createMenu(testContext.menu.data)
            testContext.changeMenu({
               ...testContext.menu,
               source: 'url',
            })
         } else {
            const fetched = await fetchData(id)
            fetched &&
               testContext.changeMenu({
                  ...testContext.menu,
                  data: fetched,
                  source: 'url',
               })
            menu = fetched && createMenu(fetched)
         }
         setData(menu)
         setLoading(false)
      }
      getData()
   }, []) // eslint-disable-line react-hooks/exhaustive-deps

   return (
      <>
         <Helmet>
            <meta name="robots" content="noindex,nofollow" />
         </Helmet>
         <Seo />
         <Layout url={location.pathname}>
            <TreePageHeader items={data} refreshData={refreshData} />
            {data ? (
               <>
                  <HiddenSmall>
                     {testContext.menu.type !== 'mobile' ? (
                        <>
                           <TreeNavigation items={data} loading={loading} />
                           <TreeSwitcher />
                        </>
                     ) : (
                        <MobileNavigation loading={loading} items={data} />
                     )}
                  </HiddenSmall>
                  <HiddenBig>
                     <MobileNavigation loading={loading} items={data} />
                  </HiddenBig>
               </>
            ) : (
               <TreePageError />
            )}
         </Layout>
      </>
   )
}

export default TreePage
