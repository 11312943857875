import React from 'react'

const IconMegaMenu = () => (
   <svg
      width="216"
      height="116"
      viewBox="0 0 216 116"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <rect y="0.845703" width="68" height="18" rx="7.76629" fill="#FFE4DE" />
      <rect
         x="148"
         y="51.8457"
         width="68"
         height="18"
         rx="7.76629"
         fill="#FFE4DE"
      />
      <rect
         x="4"
         y="4.8457"
         width="60"
         height="10.3551"
         rx="5.17753"
         fill="#F58677"
      />
      <rect
         x="78"
         y="4.8457"
         width="60"
         height="10.3551"
         rx="5.17753"
         fill="#F58677"
      />
      <rect
         x="152"
         y="4.8457"
         width="60"
         height="10.3551"
         rx="5.17753"
         fill="#F58677"
      />
      <rect
         x="4"
         y="31.2012"
         width="60"
         height="10.3551"
         rx="5.17753"
         fill="#F5F4F3"
      />
      <rect
         x="78"
         y="31.8457"
         width="60"
         height="10.3551"
         rx="5.17753"
         fill="#F5F4F3"
      />
      <rect
         x="152"
         y="31.8457"
         width="60"
         height="10.3551"
         rx="5.17753"
         fill="#F5F4F3"
      />
      <rect
         x="4"
         y="55.5566"
         width="60"
         height="10.3551"
         rx="5.17753"
         fill="#F5F4F3"
      />
      <rect
         x="78"
         y="56.2012"
         width="60"
         height="10.3551"
         rx="5.17753"
         fill="#F5F4F3"
      />
      <rect
         x="152"
         y="55.8457"
         width="60"
         height="10.3551"
         rx="5.17753"
         fill="#F5F4F3"
      />
      <rect
         x="4"
         y="79.9121"
         width="60"
         height="10.3551"
         rx="5.17753"
         fill="#F5F4F3"
      />
      <rect
         x="78"
         y="80.5566"
         width="60"
         height="10.3551"
         rx="5.17753"
         fill="#F5F4F3"
      />
      <rect
         x="152"
         y="80.8457"
         width="60"
         height="10.3551"
         rx="5.17753"
         fill="#F5F4F3"
      />
      <rect
         x="4"
         y="104.268"
         width="60"
         height="10.3551"
         rx="5.17753"
         fill="#F5F4F3"
      />
      <rect
         x="78"
         y="104.912"
         width="60"
         height="10.3551"
         rx="5.17753"
         fill="#F5F4F3"
      />
      <rect
         x="152"
         y="104.846"
         width="60"
         height="10.3551"
         rx="5.17753"
         fill="#F5F4F3"
      />
   </svg>
)

export default IconMegaMenu
