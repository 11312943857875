import React from 'react'
import { useTheme } from 'styled-components'
import PropTypes from 'prop-types'

const IconCopy = ({ color }) => {
   const theme = useTheme()
   return (
      <svg
         width="18"
         height="21"
         viewBox="0 0 18 21"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <mask id="path-1-inside-1_411_93" fill={theme.color[color]}>
            <path
               fillRule="evenodd"
               clipRule="evenodd"
               d="M2 2.5H12V4.5H14V2.5C14 1.39543 13.1046 0.5 12 0.5H2C0.895431 0.5 0 1.39543 0 2.5V14.5C0 15.6046 0.89543 16.5 2 16.5H4V14.5H2L2 2.5Z"
            />
         </mask>
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 2.5H12V4.5H14V2.5C14 1.39543 13.1046 0.5 12 0.5H2C0.895431 0.5 0 1.39543 0 2.5V14.5C0 15.6046 0.89543 16.5 2 16.5H4V14.5H2L2 2.5Z"
            fill={theme.color[color]}
         />
         <path
            d="M12 2.5H13V1.5H12V2.5ZM2 2.5V1.5H1V2.5H2ZM12 4.5H11V5.5H12V4.5ZM14 4.5V5.5H15V4.5H14ZM4 16.5V17.5H5V16.5H4ZM4 14.5H5V13.5H4V14.5ZM2 14.5H1V15.5H2V14.5ZM12 1.5H2V3.5H12V1.5ZM13 4.5V2.5H11V4.5H13ZM12 5.5H14V3.5H12V5.5ZM15 4.5V2.5H13V4.5H15ZM15 2.5C15 0.843146 13.6569 -0.5 12 -0.5V1.5C12.5523 1.5 13 1.94772 13 2.5H15ZM12 -0.5H2V1.5H12V-0.5ZM2 -0.5C0.343146 -0.5 -1 0.843146 -1 2.5H1C1 1.94772 1.44772 1.5 2 1.5V-0.5ZM-1 2.5V14.5H1V2.5H-1ZM-1 14.5C-1 16.1569 0.343146 17.5 2 17.5V15.5C1.44772 15.5 1 15.0523 1 14.5H-1ZM2 17.5H4V15.5H2V17.5ZM5 16.5V14.5H3V16.5H5ZM2 15.5H4V13.5H2V15.5ZM1 2.5L1 14.5H3V2.5H1Z"
            fill={theme.color[color]}
            mask="url(#path-1-inside-1_411_93)"
         />
         <rect
            x="5"
            y="5.5"
            width="12"
            height="14"
            rx="1"
            stroke={theme.color[color]}
            strokeWidth="2"
         />
      </svg>
   )
}

export default IconCopy

IconCopy.propTypes = {
   color: PropTypes.string,
}

IconCopy.defaultProps = {
   color: 'white',
}
