import React from 'react'
import { useTheme } from 'styled-components'
import PropTypes from 'prop-types'

const IconDesktop = ({ color }) => {
   const theme = useTheme()
   return (
      <svg
         width="21"
         height="19"
         viewBox="0 0 21 19"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <rect
            x="1.43555"
            y="15"
            width="14"
            height="18"
            rx="1"
            transform="rotate(-90 1.43555 15)"
            stroke={theme.color[color]}
            strokeWidth="2"
         />
         <path
            d="M6.57227 18H14.2995"
            stroke={theme.color[color]}
            strokeWidth="2"
         />
      </svg>
   )
}

export default IconDesktop

IconDesktop.propTypes = {
   color: PropTypes.string,
}

IconDesktop.defaultProps = {
   color: 'black',
}
