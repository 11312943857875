import React from 'react'
import { useTheme } from 'styled-components'
import PropTypes from 'prop-types'

const IconMobile = ({ color }) => {
   const theme = useTheme()
   return (
      <svg
         width="14"
         height="19"
         viewBox="0 0 14 19"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <rect
            x="1.51172"
            y="1"
            width="11"
            height="17"
            rx="1"
            stroke={theme.color[color]}
            strokeWidth="2"
         />
         <line
            x1="5.19336"
            y1="15.5"
            x2="8.82972"
            y2="15.5"
            stroke={theme.color[color]}
         />
         <path d="M6.10156 3.49854H7.91974" stroke={theme.color[color]} />
      </svg>
   )
}

export default IconMobile

IconMobile.propTypes = {
   color: PropTypes.string,
}

IconMobile.defaultProps = {
   color: 'black',
}
