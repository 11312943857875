import React from 'react'
import { useTheme } from 'styled-components'
import PropTypes from 'prop-types'

const IconRefresh = ({ color }) => {
   const theme = useTheme()
   return (
      <svg
         width="16"
         height="21"
         viewBox="0 0 16 21"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M7.97266 6.34687L7.97266 0L11.0811 2.48675C11.5211 2.83879 11.5211 3.50808 11.0811 3.86012L7.97266 6.34687Z"
            fill={theme.color[color]}
         />
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 10.3371C2 6.97234 4.72771 4.24463 8.09251 4.24463V2.24463C3.62314 2.24463 0 5.86777 0 10.3371C0 12.4647 0.821039 14.4005 2.16356 15.845L3.74081 14.6011C2.66396 13.5022 2 11.9972 2 10.3371Z"
            fill={theme.color[color]}
         />
         <path
            d="M7.85156 14.0867L7.85156 20.4336L4.74313 17.9468C4.30307 17.5948 4.30307 16.9255 4.74312 16.5735L7.85156 14.0867Z"
            fill="#243433"
         />
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.8242 10.0964C13.8242 13.4613 11.0965 16.189 7.7317 16.189L7.7317 18.189C12.2011 18.189 15.8242 14.5658 15.8242 10.0964C15.8242 7.96887 15.0032 6.03305 13.6607 4.58856L12.0834 5.83248C13.1603 6.93135 13.8242 8.43636 13.8242 10.0964Z"
            fill={theme.color[color]}
         />
      </svg>
   )
}

export default IconRefresh

IconRefresh.propTypes = {
   color: PropTypes.string,
}

IconRefresh.defaultProps = {
   color: 'black',
}
