import React from 'react'
import styled, { css } from 'styled-components'
import StructureItem from '../../atoms/StructureItem/StructureItem'

const Wrapper = styled.div`
   overflow: hidden;
   transition: ${({ theme }) => theme.transition};
   ${({ second }) =>
      second &&
      css`
         height: 0;
         margin-left: 8px;
         &.active {
            height: auto;
            margin-top: 1rem;
         }
      `}
   ${({ third }) =>
      third &&
      css`
         height: 1;
         margin-left: 8px;
         &.active {
            height: auto;
            margin-top: 1rem;
         }
      `};
`

const Inner = styled.ul`
   display: flex;
   flex-direction: column;
   gap: 1rem;
`
const Structure = ({ items, second, third, active }) => {
   return (
      <Wrapper
         className={active ? 'active' : null}
         second={second}
         third={third}
      >
         <Inner>
            {items?.map((item) => (
               <StructureItem
                  second={second}
                  third={third}
                  items={item.children}
                  key={item['ID']}
               >
                  {item['Anchor']}
               </StructureItem>
            ))}
         </Inner>
      </Wrapper>
   )
}

export default Structure
