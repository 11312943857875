import React from 'react'
import styled from 'styled-components'
import Dropdown from '../Dropdown/Dropdown'
import arrow from '../../../assets/images/dropdown-arrow.svg'

const Wrapper = styled.ul`
   width: 100%;
   display: flex;
`
const Item = styled.li`
   font-weight: 500;
   padding: 0 1.5rem;
   flex-shrink: 0;
   & > span {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      position: relative;
      padding: 1.5rem 0;
      cursor: pointer;
      img {
         transform: rotate(90deg);
         width: 6px;
      }
   }
   &:first-child {
      padding-left: 0;
   }
   &:last-child {
      padding-right: 0;
   }
   & > span:after {
      content: '';
      display: block;
      position: absolute;
      height: 1.5px;
      width: 100%;
      left: 0;
      bottom: -2px;
      transition: ${({ theme }) => theme.transition};
   }
   &:hover > span:after {
      background-color: ${({ theme }) => theme.color.primary};
   }
   & > .dropdown {
      opacity: 0;
      visibility: hidden;
   }
   &:hover {
      .dropdown {
         opacity: 1;
         visibility: visible;
      }
   }
`

const DesktopNavigation = ({ items, loading }) => {
   return (
      <Wrapper>
         {loading ? (
            <Item>
               <span>Wczytuję...</span>
            </Item>
         ) : (
            items.map((item) => (
               <Item key={item['ID']}>
                  <span>
                     {item['Anchor']}{' '}
                     {item.children.length > 0 && (
                        <img src={arrow} alt="arrow" />
                     )}
                  </span>

                  {item.children?.length ? (
                     <Dropdown items={item.children} />
                  ) : null}
               </Item>
            ))
         )}
      </Wrapper>
   )
}

export default DesktopNavigation
