import React, { useContext } from 'react'
import styled from 'styled-components'
import Container from '../../atoms/Container/Container'
import { myContext } from '../../../../provider'
import DesktopDropdown from '../DesktopDropdown/DesktopDropdown'
import MegaDropdown from '../MegaDropdown/MegaDropdown'

const Wrapper = styled.div`
   width: 100%;
   height: 60vh;
   padding: 1.5rem 0;
   position: absolute;
   left: 0;
   top: calc(100% + 2px);
   background-color: ${({ theme }) => theme.color.white};
   box-shadow: ${({ theme }) => theme.shadow1.hover};
   z-index: 90;
   transition: ${({ theme }) => theme.transition};
   overflow: hidden;
`

const InnerContainer = styled.div`
   width: 100%;
   height: 100%;
   position: relative;
`

const Dropdown = ({ items }) => {
   const testContext = useContext(myContext)
   return (
      <Wrapper className="dropdown">
         <Container>
            <InnerContainer
               className={testContext.menu.type === 'mega' ? 'scroll' : ''}
            >
               {testContext.menu.type === 'desktop' && (
                  <DesktopDropdown items={items} />
               )}
               {testContext.menu.type === 'mega' && (
                  <MegaDropdown items={items} />
               )}
            </InnerContainer>
         </Container>
      </Wrapper>
   )
}

export default Dropdown
